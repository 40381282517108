import { Injectable, OnDestroy } from '@angular/core';
import { CONSTANTS } from 'app/constants';
import { EmployeeDto, EmployeeMeInputDto, UserMeOutputDto } from 'app/shared/models/employee.models';
import { Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IdamService } from './idam.service';
import { RestService } from './request.service';
import { PostMeAvatarResponse } from '../models/postmeavatarresponse.model';
import { PoolingPreferenceDto } from '../models/mPooling.models';


@Injectable({
  providedIn: 'root'
})
export class DipendenteService implements OnDestroy {
  private _unsubscribeAll$ = new Subject<void>();

  //
  // private _profileDipendenteCompleted$: BehaviorSubject<boolean | undefined> =
  //   new BehaviorSubject<boolean | undefined>(undefined);

  constructor(
    private restService: RestService,
    private idamService: IdamService
  ) {
    const json: string = localStorage.getItem(CONSTANTS.userClient) ?? '';
    if (json === '') {
      // this.loadDipendente();
      // this.dipendente$.next(undefined);
    } else {
      // const dipendente = JSON.parse(json) as EmployeeDto;
      // this.dipendente$.next(dipendente);
      // this.checkProfileCompleted(dipendente);
    }
  }

  // get profileDipendenteCompleted$(): Observable<boolean | undefined> {
  //   return this._profileDipendenteCompleted$.asObservable();
  // }

  // getDipendente$(): Observable<EmployeeDto | undefined> {
  //   return this.dipendente$.asObservable();
  // }

  // loadDipendente(): void {
  //
  //   if (this.idamService.isLogged()) {
  //
  //     this.getDipendenteInfoFromServer()
  //       .pipe(
  //         takeUntil(this._unsubscribeAll$),
  //       )
  //       .subscribe((dipendete) => {
  //         this.setDipendente(dipendete);
  //       });
  //   }
  // }

  getDipendenteInfoFromServer(): Observable<EmployeeDto> {
    return this.restService
      .get$<EmployeeDto>('/employee/me')
      .pipe(
        catchError((err) => {
          localStorage.removeItem(CONSTANTS.userClient);
          this.idamService.logout();
          //this.dipendente$.next(undefined);
          throw err;
        })
      );
  }


  getUserMeInfo(): Observable<UserMeOutputDto> {

    return this.restService.get$<UserMeOutputDto>(
      '/users/me',
      undefined,
      false,
      'gateway'
    );
  }


  // notifyDipendenteChanges(dipendente: EmployeeDto): void {
  //   this.setDipendente(dipendente);
  // }

  // notifyUpdateEmployeePooling(poolingSettings: Pick<EmployeeDto, 'poolingPreference'> |
  //                             Pick<EmployeeDto, 'travelInformation'> |
  //                             Pick<EmployeeDto, 'transportMeans'> |
  //                             EmployeeMeInputDto
  //                             ): void {
  //   if (this.dipendente$ && this.dipendente$.value) {
  //     const newValue = Object.assign(this.dipendente$.value, poolingSettings);
  //     this.notifyDipendenteChanges(newValue);
  //   }
  // }
  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.complete();
  }


  // isEmployeeProfileCompleted(dipendente: EmployeeDto): boolean {
  //   return (
  //     dipendente &&
  //     dipendente.phone !== '' &&
  //     dipendente.poolingPreference !== undefined &&
  //     dipendente.poolingPreference?.transportTypes !== undefined &&
  //     dipendente.poolingPreference?.transportTypes?.length > 0 &&
  //     dipendente.travelInformation !== undefined &&
  //     dipendente.travelInformation !== null
  //   );
  // }

  // isEmployeeProfileCompletedToOfferPass(dipendente: EmployeeDto): boolean{
  //   return (
  //     dipendente &&
  //     dipendente.phone !== '' &&
  //     dipendente.poolingPreference !== undefined &&
  //     dipendente.poolingPreference?.transportTypes !== undefined &&
  //     dipendente.poolingPreference?.transportTypes?.length > 0 &&
  //     dipendente.travelInformation !== undefined &&
  //     dipendente.travelInformation !== null &&
  //       dipendente.transportMeans !== undefined &&
  //         dipendente.transportMeans.length > 0 &&
  //         dipendente.withAssuranceAndDrivingLicense === true
  //   );
  // }

  updateAnagrafica(employeeSettings: EmployeeMeInputDto): Observable<EmployeeMeInputDto> {
    return this.restService.put$<EmployeeMeInputDto>('/employee/me', employeeSettings);
  }

  uploadImage(formData: any): Observable<PostMeAvatarResponse> {
    return this.restService.post$<PostMeAvatarResponse>('/users/me/avatar', formData, undefined, 'gateway').pipe(
      // tap(() => this.loadDipendente()),
    );

  }

  updateEmployeePoolingPreferences(poolingData: PoolingPreferenceDto): Observable<PoolingPreferenceDto> {

    return this.restService
      .post$<PoolingPreferenceDto>(
        '/employee/me/pooling-preference',
        poolingData
      )
      .pipe(

      );
  }

  // private setDipendente(dipendente: EmployeeDto): void {
  //   if (!dipendente || dipendente.poolingPreference === undefined) {
  //     dipendente.poolingPreference = {
  //       transportTypes: [],
  //       withEmployeesOfOtherCompanies: false,
  //       withSmokersOffer: false,
  //       withSmokersRequest: false,
  //       withOnlyWoman: false,
  //     };
  //   }
  //   if (localStorage.getItem(CONSTANTS.userClient)) {
  //     localStorage.removeItem(CONSTANTS.userClient);
  //   }
  //
  //   const dt = JSON.stringify(dipendente);
  //   localStorage.setItem(CONSTANTS.userClient, dt);
  //   this.dipendente$.next(dipendente);
  //
  //   this.checkProfileCompleted(dipendente);
  // }
  //
  // private checkProfileCompleted(dipendente: EmployeeDto): void {
  //   if (this.isEmployeeProfileCompleted(dipendente)) {
  //     this._profileDipendenteCompleted$.next(true);
  //   } else {
  //     this._profileDipendenteCompleted$.next(false);
  //   }
  // }


}
